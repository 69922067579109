<div *ngIf="news.length" class="newspanel mat-elevation-z3">
  <div class=" d-flex justify-content-between ">
    <!--<div  class="combined-truncation">
      <span class="mt-2 ms-2 text-white font-weight-bold text-decoration-bold ">{{ news[0].newsTitle}} :</span>
      <span class="ms-2 text-white"> {{news[0].newsDescription }} </span>
    </div>-->
    <div>
      <label class="mt-2 ms-2 text-white font-weight-bold text-decoration-bold ">{{ news[0].newsTitle | truncate:120: '...'}} :</label>
      <label *ngIf="news[0].newsTitle.length < 120" class="ms-2 text-white"> {{news[0].newsDescription | truncate:120 - news[0].newsTitle.length: '...' }} </label>
    </div>
    <div *ngIf="news.length > 1" class="text-white align-self-center">
      <button mat-button class="me-2 text-white" (click)="openNewsDialog(news)">See More</button>

    </div>
  </div>
</div>
<div class="user-background">

  <div class="text-center">
    <h2>Applications</h2>
  </div>
  <mat-tab-group [(selectedIndex)]="activeIndex" (keydown.arrowright)="onArrowRight()" (keydown.arrowleft)="onArrowLeft()" mat-stretch-tabs="false" mat-align-tabs="center" class="p-2" color="primary">
    <mat-tab label="PUBLIC">

      <div class="d-md-flex d-xl-flex d-lg-flex flex-wrap justify-content-center cardbox-rows">

        <div *ngFor="let x of publicApps ">
          <mat-card class="card">


            <div style="text-align: center;">
              <!-- <mat-icon color="primary"  style="font-size: 50px; height: 50px; width: 50px;">install_desktop</mat-icon> -->
              <img class="mt-4" src="{{x.iconUrl}}" width="30%">
              <br>
              <br>
              <h2 class="card-header-m ">{{x.appTitle}}</h2>
            </div>



            <mat-card-content>
              <p style="text-align: center;" class="text-secondary">
                {{x.appDescription}}
              </p>
            </mat-card-content>

            <!--<div style=" align-self: center;" >
              <mat-card-actions style=" vertical-align: bottom;" >
                <button color="secondary"  mat-raised-button (click)="gotoLaunch(x.link)">Launch</button>
              </mat-card-actions>
            </div>-->
            <mat-card-footer style="align-self: center;" class="position-absolute bottom-0  w-100">
              <div class="w-100">

                <mat-card-actions class="container d-flex justify-content-center align-items-center text-center mt-4" style="">
                  <button color="secondary" mat-raised-button (click)="gotoLaunch(x.link)">Launch</button>
                </mat-card-actions>

                <div class="d-flex justify-content-between align-items-center w-100">
                  <!-- Health Label and Icon -->
                  <div class="d-flex align-items-center">
                    <label class="mb-0 me-2">Health:</label>
                       <div *ngIf="x.type!='PowerApp'">
                    <img *ngIf="x.health" src="health_active.png" height="25px">
                    <img *ngIf="!x.health" src="health_d.png" height="25px">
                      </div>
                    <img  *ngIf="x.type=='PowerApp'"  src="health_active.png" height="25px">
                  </div>

                  <!-- More Options Button -->
                  <div>
                    <button mat-icon-button color="primary" (click)="openDialog(x)"><mat-icon>info</mat-icon></button>
                  </div>
                </div>
              </div>

            </mat-card-footer>
          </mat-card>
        </div>





      </div>

    </mat-tab>
    <mat-tab label="SERVICE">
      <div class="d-md-flex d-xl-flex d-lg-flex flex-wrap justify-content-center cardbox-rows">

        <div *ngFor="let x of serviceApps ">
          <mat-card class="card">


            <div style="text-align: center;">
              <!-- <mat-icon color="primary"  style="font-size: 50px; height: 50px; width: 50px;">install_desktop</mat-icon> -->
              <img class="mt-4" src="{{x.iconUrl}}" width="30%">
              <br>
              <br>
              <h2 class="card-header-m ">{{x.appTitle}}</h2>
            </div>



            <mat-card-content>
              <p style="text-align: center;" class="text-secondary">
                {{x.appDescription}}
              </p>
            </mat-card-content>

            <mat-card-footer style="align-self: center;" class="position-absolute bottom-0  w-100">
              <div class="w-100">

                <mat-card-actions class="container d-flex justify-content-center align-items-center text-center mt-4" style="">
                  <button color="secondary" mat-raised-button (click)="gotoLaunch(x.link)">Launch</button>
                </mat-card-actions>

                <div class="d-flex justify-content-between align-items-center w-100">
                  <!-- Health Label and Icon -->
                  <div class="d-flex align-items-center">
                    <label class="mb-0 me-2">Health:</label>
                    <img *ngIf="x.health" src="health_active.png" height="25px">
                    <img *ngIf="!x.health" src="health_d.png" height="25px">
                  </div>

                  <!-- More Options Button -->
                  <div>
                    <button mat-icon-button (click)="openDialog(x)"><mat-icon>more_vert</mat-icon></button>
                  </div>
                </div>
              </div>

            </mat-card-footer>

          </mat-card>
        </div>

        <div class="text-center mat-elevation-z1" matRipple matRippleColor="#7030A044" [matRippleCentered]="centered" style="background-color: rgb(255, 255, 255); border-radius: 10px; height: 300px; width: 50%;" *ngIf="serviceApps.length==0">
          <img src="logo.png" class="text-center mt-4" height="90px">
          <h1 class="mt-4">Oops! No apps available at the moment. Stay tuned for updates!</h1>
          <p class="mt-4">Need access to an app? Head over to the <b mat-badge>+Request Access</b> and submit your access request!</p>
        </div>





      </div>
    </mat-tab>
    <mat-tab label="RESTRICTED">
      <div class="d-md-flex d-xl-flex d-lg-flex flex-wrap justify-content-center cardbox-rows">

        <div *ngFor="let x of restrictedApps ">
          <mat-card class="card">


            <div style="text-align: center;">
              <!-- <mat-icon color="primary"  style="font-size: 50px; height: 50px; width: 50px;">install_desktop</mat-icon> -->
              <img class="mt-4" src="{{x.iconUrl}}" width="30%">
              <br>
              <br>
              <h2 class="card-header-m ">{{x.appTitle}}</h2>
            </div>



            <mat-card-content>
              <p style="text-align: center;" class="text-secondary">
                {{x.appDescription}}
              </p>
            </mat-card-content>
            <!--<mat-card-actions style="align-self: center;">
              <button color="secondary" class="mt-2" mat-raised-button (click)="gotoLaunch(x.link)">Launch</button>
            </mat-card-actions>

            <mat-card-footer>

              <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 25px; width: 100%;">-->
            <!-- Health Label and Icon -->
            <!--<div class="d-flex align-items-center">
              <label class="mb-0 me-2">Health:</label>
              <img *ngIf="x.health" src="health_active.png" height="25px">
              <img *ngIf="!x.health" src="health_d.png" height="25px">
            </div>-->
            <!-- More Options Button -->
            <!--<div>
                  <button mat-icon-button (click)="openDialog(x)"><mat-icon>more_vert</mat-icon></button>
                </div>
              </div>


            </mat-card-footer>-->
            <mat-card-footer style="align-self: center;" class="position-absolute bottom-0  w-100">
              <div class="w-100">

                <mat-card-actions class="container d-flex justify-content-center align-items-center text-center mt-4" style="">
                  <button color="secondary" mat-raised-button (click)="gotoLaunch(x.link)">Launch</button>
                </mat-card-actions>

                <div class="d-flex justify-content-between align-items-center w-100">
                  <!-- Health Label and Icon -->
                  <div class="d-flex align-items-center">
                    <label class="mb-0 me-2">Health:</label>
                    <img *ngIf="x.health" src="health_active.png" height="25px">
                    <img *ngIf="!x.health" src="health_d.png" height="25px">
                  </div>

                  <!-- More Options Button -->
                  <div>
                    <button mat-icon-button (click)="openDialog(x)"><mat-icon>more_vert</mat-icon></button>
                  </div>
                </div>
              </div>

            </mat-card-footer>


          </mat-card>
        </div>





      </div>
    </mat-tab>
  </mat-tab-group>






</div>

<div>

</div>
<!-- Services we can perform -->
