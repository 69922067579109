import { Component, Inject } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-userdailog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatIconButton, MatIcon],
  templateUrl: './userdailog.component.html',
  styleUrl: './userdailog.component.scss'
})
export class UserDailogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { value: any }) {
  }


}
