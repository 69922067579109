<mat-toolbar class="toolbar-background mat-elevation-z2 ">
   
    <span><img src="Acc_Logo_Black_Purple_RGB.png" height="30px"></span>
    &nbsp; 
    <div class="verticalLine">
    </div> 
    <span><img src="logo.png" height="30px"></span>
   
    <h1 class="fill-space ms-2 font-weight-bold"><b>UCP HUB</b></h1>
  
   
    <div class="">
        <div style="float:unset">
          
              <button (click)="login()" mat-flat-button color="primary">
            Login
              </button>
        </div>
    </div>
    
 

  </mat-toolbar>