import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntervalProcessService {

  constructor() { }

  public  interval: any;

  startInterval(intervalDuration:number,callback: () => void) {

    if(this.interval==null)
    this.interval = setInterval(callback, intervalDuration);
  }

  clearInterval() {
    if (this.interval) {

   
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
