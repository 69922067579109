<!-- <div style=" ;">
  <h3 class="fs-5" style="flex-shrink:initial" mat-dialog-title>
    News
  </h3>
  <mat-dialog-actions>
    <button mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
<div class="carousel-nav m-4">
  <button mat-icon-button (click)="prev()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-icon-button (click)="next()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
<mat-dialog-content class="pt-0 mat-typography">
  <div class="carousel-container">

    <div class="">


      <H3> {{data.value[currentIndex].newsTitle}}.</H3>


      <p class="" style=" word-wrap: break-word;">
        {{data.value[currentIndex].newsDescription}}
      </p>

    </div>


  </div>

</mat-dialog-content> -->



<mat-dialog-content class="news-dialog-content">
  <!-- Dialog Header -->
  <div class="dialog-header">
    <h3 class="dialog-title">All News</h3>
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="container row">
    <div class="col-1">
      <button mat-icon-button (click)="prev()">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </div>
    <div class="col-10">
      <!-- News Header -->
      <div class="news-header">
        <h3>{{data.value[currentIndex].newsTitle}}</h3>
      </div>

      <!-- News Details -->
      <div class="news-details">
        <p> {{data.value[currentIndex].newsDescription}}</p>
      </div>
    </div>

    <div class="col-1">
      <button mat-icon-button (click)="next()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>

      <!-- Navigation Icons -->
      <!--<div class="news-navigation">
      <button mat-icon-button (click)="prev()">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button mat-icon-button (click)="next()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>-->
    </div>
</mat-dialog-content>


