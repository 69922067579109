import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from '../../services/authentication-services/login.service';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
//import { LoginService } from 'src/app/services/authentication-services/login.service';

@Component({
  standalone:true,
  selector: 'app-toolbar-guest',
  imports:[MatToolbarModule,CommonModule,CommonModule,MatButtonModule],
  templateUrl: './toolbar-guest.component.html',
  styleUrls: ['./toolbar-guest.component.css']
})
export class ToolbarGuestComponent implements OnInit {

  constructor(public loginService:LoginService,private authService: MsalService) { }


  login(){

  //  this.loginService.setLogin(true);
   this.authService.loginRedirect();

  }
  ngOnInit(): void {
  }

}
