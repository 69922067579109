import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { LoginService } from "../services/authentication-services/login.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar, private loginService: LoginService, private _router: Router) {
    
  }

  intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
           
         console.log(error.message,error.statusText,error.status);
         let errorMessage = 'An error occurred';
      
            if(error.message==null){

                if(error.status==401){
                  errorMessage = 'Unauthorized or token is expired, requesting a new token';
                }
                if (error.status === 404) {
                    errorMessage = 'Data not found';
                  } else if (error.status === 500) {
                    errorMessage = 'Internal server error';
                  }
            }
            if(error.status==401){
             
              errorMessage = 'Unauthorized or token is expired, requesting a new token';
              this.loginService.logout();
              this._router.navigateByUrl("authentication");

            }
            if(error.status==403){
              errorMessage = 'Access forbidden. You do not have permission to access this resource.';

            }

            else{
           

              if(error.error && error.error.message ){
                errorMessage=error.error.message+" | Error Code: "+error.status;
              }
              else
              if(error.error && error.error.detail){
                errorMessage=error.error.detail+" | Error Code: "+error.status;
              }

              else{
                errorMessage=error.message+" | Error Code: "+error.status;
                 
                }


              if(error.url=="https://graph.microsoft.com/v1.0/me/photo/$value"){
                  errorMessage= '';
              }

              
               
               // errorMessage=error.error.detail+" | Error Code: "+error.status;
            }

          
        
            if(errorMessage)
              this.snackBar.open(errorMessage, 'Close', {
                duration: 6000,
                verticalPosition: 'top'
              });
    
          return throwError(()=>error);
        })
      );
  }
}
