import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from 'process';
// import {MatSnackBar as MatSnackBar} from '@angular/material/snack-bar';
  
@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  constructor(private snackBar:MatSnackBar) { }

  showWarning(message:string, action:string){

    this.snackBar.open(message,action,{
      horizontalPosition:'center',
      verticalPosition:'top',
       duration: 5000
    })
  }

  showSucess(message:string, action:string){

    this.snackBar.open(message,action,{
      horizontalPosition:'center',
      verticalPosition:'top',
      duration:5000
    })
  }
  showError(message:string, action:string){

    this.snackBar.open(message,action,{
      horizontalPosition:'center',
      verticalPosition: 'top',
      duration: 5000

    })
  }

}
