import { AfterViewInit, Component } from '@angular/core';
import { CommonLoaderService } from '../services/common-loader.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-common-loader',
  standalone:true,
  imports:[MatProgressBarModule,CommonModule],
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss']
})
export class CommonLoaderComponent implements AfterViewInit    {
 loading:boolean=false;
 
   constructor(private loadingService:CommonLoaderService){

     this.loading=false;
     

   }

   ngAfterViewInit (){
    this.loadingService.isLoading.subscribe((data:boolean)=>{

      if(data!=undefined)
      this.loading=data;

    })
   }
}
