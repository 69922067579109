<div class="background ">

  <div class="mt-4">
    <mat-card class="help-support-container">

      <mat-card-title class="h1">  </mat-card-title>

      <mat-card-content>
        <p class="h4">Help & Support</p>
        <hr>
        <p>The UCP Hub is an application designed to provide users with a centralized platform for accessing various tools and applications in one convenient location. Users can submit requests to gain access to specific app sections or individual applications by following the appropriate steps.</p>
        <p>Please contact to UCP.Engineering.Developers for queries ir questions</p>
      </mat-card-content>

    </mat-card>
  </div>

</div>


