import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { SystemInformaton } from '../models/SystemInformation';

@Injectable({
  providedIn: 'root',
  
})
export class BaseHttpclientService {

  constructor(private httpClient:HttpClient) {


    
   }


   get(endpoint:string,queryParam:string){
    return  this.httpClient.get(endpoint+"?"+queryParam);
  }
  
   getWithoutParam(endpoint:string){
    return  this.httpClient.get(endpoint);
  }


  post(endpoint:string, data:any){
    return this.httpClient.post(endpoint,data);
  }



   catchError(error:any):Observable<SystemInformaton>{

    return throwError(error);
   }

   httpGet<T>(apiurl:string){

        return this.httpClient.get<T>(apiurl).
        pipe(catchError(err=>this.catchError(err)));

   }


}
