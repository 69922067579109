import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core'; import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NewsModel } from '../../../models/home/userhome/NewsModel';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule, DatePipe, NgIf } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { BaseHttpclientService } from '../../../services/base-httpclient.service';
import { APIConstants } from '../../../../environments/APIConstants';
import { AlertMessageService } from '../../../services/alertmessage.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-news',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ CommonModule, MatError, MatCard, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatNativeDateModule, MatCardTitle, MatFormFieldModule, MatInputModule, MatButton, NgIf],
  providers: [DatePipe],
  templateUrl: './add-news.component.html',
  styleUrl: './add-news.component.css'
})
export class AddNewsComponent {

  newsForm: FormGroup;
  newsModel: NewsModel | null = null;
  today: Date = new Date();
  yesterDay: Date = new Date();
  constructor(
    private fb: FormBuilder,
    private webService: BaseHttpclientService,
    private alertMessageService: AlertMessageService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.yesterDay.setDate(this.yesterDay.getDate() - 1);
    this.newsForm = this.fb.group({
      newsId: [null],
      newsTitle: [null, [Validators.required, Validators.minLength(3)]],
      newsDescription: ['', [Validators.required, Validators.minLength(10)]],
      lastDate: ['', [Validators.required,
      this.minDateValidator(this.yesterDay)]]
    });
  }

  minDateValidator(minDate: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDate = control.value;
      if (selectedDate && selectedDate < minDate) {
        return { 'minDate': { value: control.value } };
      }
      return null;  // no validation errors
    };
  }


  ngOnInit(): void {
    if (this.newsModel) {
      this.newsForm.patchValue(this.newsModel);
    }
  }

  onSubmit(): void {
    if (this.newsForm.valid) {
      this.newsModel = this.newsForm.value;
      let news = {
        newsTitle: this.newsModel?.newsTitle,
        newsDescription: this.newsModel?.newsDescription,
        lastDate: this.newsModel?.lastDate ? this.datePipe.transform(this.newsModel?.lastDate, 'yyyy-MM-dd') : ""
      }

      const taskData = this.newsForm.value;
      if (taskData.id) {
      } else {
        this.saveNews(news)
      }
    } else {
      this.alertMessageService.showError("Please insert Valid Input","close")
    }
  }

  saveNews(news: any) {
    this.webService.post(APIConstants.EndPoints.ADD_NEWS , news).subscribe((data: any) => {
      if (data.statusCode = "200") {
        if (data.message) {
          this.alertMessageService.showSucess(String(data.message), "close")
          this.router.navigate(['/home']); 
        }
      }
    }, (err: any) => {
      console.error(err)
      this.webService.catchError(err);
    })
  }
  get newsTitle() {
    return this.newsForm.get('newsTitle');
  }
  get newsDescription() {
    return this.newsForm.get('newsDescription');
  }
  get lastDate() {
    return this.newsForm.get('lastDate');
  }

}
