<!-- <div class="footer-background position-fixed bottom-0 w-100" color="primary">
  <small class="text-secondary container d-flex justify-content-center align-items-center text-center p-2">
    {{title}} fsdfsfsfsfsdf
  </small>
</div> -->
<footer class="bg-white text-white py-3">
  <div class="container text-center">
    <div class="text-secondary d-flex justify-content-between align-items-center">
      <span>UCP HUB | &copy; Copyright 2001-{{this.year}} Accenture. All rights reserved. Accenture Confidential. For internal use only | {{ title }}</span>
      <!-- <nav>
        <a mat-button routerLink="/about" class="text-white">About</a>
        <a mat-button routerLink="/contact" class="text-white">Contact</a>
      </nav> -->
    </div>
  </div>
</footer>