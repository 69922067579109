import { environment } from "./environment";

export const APIConstants = {

    EndPoints:{
        GET_APPS: environment.API_BASE_URL+ "Apps/GetApps",
        GET_NEWS: environment.API_BASE_URL + "Apps/GetNews",
        ADD_NEWS: environment.API_BASE_URL + "Apps/AddNews"     
      },
}
