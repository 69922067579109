import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';
import { format } from 'util';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  year=new Date().getFullYear();;
  title: string ="Version:"+ environment.version+" | Environment :"+ environment.currentEnv 
}
