<div class="row">

   <div class="col-5"></div>
   <div class="col-4">

      <div class="mt-4" *ngIf="!isLoggedIn">
        
        <p class="text-start">Please sign-in to see your profile information.</p>
     </div>
     
     <div *ngIf="isLoggedIn==true" class="mt-4" >
        <p>Login successful!</p>
      
        <p class="text-start">Request your profile information by clicking Profile above.</p>
     </div>

   </div>
   <div class="col-3"></div>
 


</div>
