<mat-dialog-content class="access-request-dialog-content">
  <!-- Dialog Header -->
  <div class="dialog-header">
    <h2 class="dialog-title">How to Access Request</h2>
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Dialog Content -->
  <div class="dialog-body">
    <!-- First List -->
    <div class="list-section">
      <h3 class="list-title">Request for Service and Restricted App</h3>
      <ol>
        <li>If you need access to the <strong>Service Apps</strong> or <strong>Restricted Apps</strong> section, follow these steps:</li>
        <li>Obtain approval from your lead.</li>
        <li>Send an email with the approval document attached to <strong>UCP.Engineering.Developers</strong>.</li>
        <li>The team will review your request and grant access. This process may take 24-48 hours.</li>
      
      </ol>
    </div>

    <!-- Second List -->
    <div class="list-section">
      <h3 class="list-title">Request Access for selected app</h3>
      <ol>
        <li>Each application may have a unique process for granting access.</li>
  <li>Navigate to the app tile and click the <strong>info icon</strong> to view details.</li>
  <li>On the details page, locate the <strong>Point of Contact (POC)</strong> for the application.</li>
  <li>Send an email to the POC, with a copy (CC) to <strong>UCP.Engineering.Developers</strong>.</li>
  <li>The review team will evaluate your request and grant access upon approval.</li>
      </ol>
    </div>
  </div>
</mat-dialog-content>
