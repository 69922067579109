import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { LoginService } from '../services/authentication-services/login.service';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardMdImage, MatCardModule } from '@angular/material/card';

import { CommonModule } from '@angular/common';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';


@Component({
  selector: 'app-authentication-initiator',
  templateUrl: './authentication-initiator.component.html',
  styleUrls: ['./authentication-initiator.component.scss'],
  standalone:true,
  imports:[MatIconModule, MatCardModule, MatButtonModule, RouterLink, RouterModule, CommonModule]
})


export class AuthenticationInitiatorComponent implements OnInit, OnDestroy,AfterViewInit {


  isLoggedIn=false;
  forceLogout=false;
  title = "CORA Web" + ': Authentication ';
  isIframe = false;
  loginDisplay = false;
  users = { userName: '', emailID: '' };

  



  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }


  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,

  private msalBroadcastService: MsalBroadcastService,public loginService: LoginService, private _router: Router, private authService: MsalService) {



  

  }
  ngOnDestroy(): void {
   
  }
  ngAfterViewInit():void{
  }

  ngOnInit(): void {
   

    

    this.loginService.isForcedLogout.subscribe((data:boolean)=>{

      this.forceLogout=data;

    });
 

   this.loginService.IsAuthenticated.subscribe((auth:boolean)=>{

   
      if(auth==true){
        this._router.navigate(['/home']);
        this.isLoggedIn=true;
      }
      else{
        this.isLoggedIn=false;
        this.loginRedirect();
      }


   });

   
  }


 

}
