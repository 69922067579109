import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  constructor() { }
  MAX_RETRY=10;
  current_retry=0;
  isConnected=false;

  currentValue=0;
  bufferValue=0;
}
