import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserAuthentication } from '../../models/UserAuthentication';
import { LoginService } from '../../services/authentication-services/login.service';
import { IntervalProcessService } from '../../services/interval-process.service';

import { SessionManagerService } from '../../shared/SharedServices/session-manager.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { RequestAccessDailogComponent } from '../../shared/request-access-dailog/request-access-dailog.component';


@Component({
  selector: 'app-toolbar-user',
  standalone:true,
  imports: [RequestAccessDailogComponent,MatIconModule,MatMenuModule,MatTooltipModule,RouterModule,MatToolbarModule,CommonModule,MatButtonModule,MatIconModule,MatMenuModule],
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.css']
})
export class ToolbarUserComponent implements OnInit,OnDestroy  {

  version = environment.version;
  userData!:UserAuthentication;
  isLoggedIn=false;
  userPic!:any;
  currentFailed=0;
  maxFailed=100;
  isAdmin=false;
  constructor(private intervalService:IntervalProcessService, public loginService:LoginService,private authService: MsalService,private sessionManager:SessionManagerService,private activeRoute:ActivatedRoute) { }
  

  ngOnDestroy(): void {
    this.intervalService.clearInterval();
  }
  sessionId:any="";
 isSessionActive=false;
 

  getIsAdmin(){

     


   }

  ngOnInit(): void {

   
    this.loginService.getProfilePicture((data:any)=>{
      if(data!=null)
      this.userPic=data;
    })

    //this.loginService.login();
    this.loginService.IsAuthenticated.subscribe(data=>{
        this.isLoggedIn=data;
    })
    this.loginService.userData.subscribe((data:UserAuthentication)=>{

        this.userData=data;
      

    
    });
    this.activeRoute.queryParams
    .subscribe((params:any) => {
   
      if(params.id!=undefined && params.id!="")
      {
        
          this.sessionManager.setSessionId(this.sessionId);
        
         
      }
      else
      {
        this.isSessionActive=false;
       
      }
      
      
    });


  
  


    this.sessionManager.connectionStatus.subscribe(data=>{

        this.isSessionActive=data;

    });


    this.sessionManager.sessionId.subscribe(data=>{

      if(this.sessionId==undefined || this.sessionId==""){

        if(!data){
        
         
        }
        else
        {
          this.sessionId=data;
       
          this.sessionManager.setSessionId(this.sessionId);
          if(this.sessionId)
          this.intervalService.startInterval(10000,()=>{
      
            
      
      
      
          });
        
        }



      }
      
      
    })


  }

  getLastSession(){
  
  }

  logout(){


    this.authService.instance.logoutRedirect();
   
  }
  readonly dialog = inject(MatDialog);
  openRequestAccessDialog() {
    this.dialog.open(RequestAccessDailogComponent);
  }

}
