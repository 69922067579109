import { Component, HostListener, Inject } from '@angular/core';

import { LoginService } from './services/authentication-services/login.service';
import { ToolbarGuestComponent } from "./toolbar/toolbar-guest/toolbar-guest.component";
import { ToolbarUserComponent } from "./toolbar/toolbar-user/toolbar-user.component";
import { CommonLoaderComponent } from './common-loader/common-loader.component';

import { CommonModule, NgClass } from '@angular/common';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
  SilentRequest,
} from '@azure/msal-browser';

import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { MatButtonModule } from '@angular/material/button';
import { CommonLoaderService } from './services/common-loader.service';
import { UserAuthentication } from './models/UserAuthentication';
import { UserhomeComponent } from './home/userhome/userhome.component';
import { FooterComponent } from './footer/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgClass, FooterComponent, ToolbarGuestComponent, MatButtonModule, ToolbarUserComponent, CommonLoaderComponent, RouterOutlet, CommonModule, MsalModule, RouterLink, UserhomeComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'cora-web-ang';
  isLoggedIn = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  isScrollNeeded: boolean = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, private _router: Router,
    private msalBroadcastService: MsalBroadcastService, private loginService: LoginService,
    private router: Router
  ) {
    this.footerClass = 'fixed';
    router.events.subscribe((val) => {
      this.footerchange()
    }
    )
  }

  footerClass = 'relative'; // default class

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.footerchange()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.footerchange()
  }

  footerchange() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    // When scrolled near the bottom, make footer fixed
    if (scrollPosition + windowHeight >= documentHeight ) {
      this.footerClass = 'fixed';
    } else {
      this.footerClass = 'relative';
    }
  }


  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();

        this.checkAndSetActiveAccount();

      });
  }


  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);



    }
    this.authService.acquireTokenSilent(this.msalGuardConfig.authRequest as SilentRequest).subscribe((data: any) => {


      localStorage.setItem("mstoken", data.accessToken);
      localStorage.setItem("email", data.account.username);
      localStorage.setItem("name", data.account.name);
      localStorage.setItem("isAuthenticated", "true");

      var ta: UserAuthentication = {
        EmailId: data.account.username,
        IsAuthenticated: true,
        Name: data.account.name

      };
      this.loginService.setLoginData(ta);
      // this._router.navigate(['/signin-oidc']);
      this.isLoggedIn = true;



    })

  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
      localStorage.setItem("mstoken", "");
      localStorage.setItem("email", "");
      localStorage.setItem("name", "");
      localStorage.setItem("isAuthenticated", "false");
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
