import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';

@Component({
  selector: 'app-help-and-support',
  standalone: true,
  imports: [CommonModule,MatCard,MatCardTitle,MatCardContent],
  templateUrl: './help-and-support.component.html',
  styleUrl: './help-and-support.component.css'
})
export class HelpAndSupportComponent {

}
