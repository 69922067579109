export const environment = {
  production: false,
  currentEnv:"Stage",
  
  conf:"testtest",
  API_BASE_URL:"https://ucphub.ciostage.accenture.com/",
  
  apiConfig:{
    uri:"https://graph.microsoft.com/v1.0/me",
    scopes:["user.read"]
  },

  auth:{
    clientId: '41d99efd-23ab-40a7-9fb7-de5faaf0f0e0',
    authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/',
    redirectUri: 'https://ucphub.ciostage.accenture.com/',
    postLogoutRedirectUri: "https://ucphub.ciostage.accenture.com/"
  },
 
  version: "0.1"
};
