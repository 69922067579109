import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon, MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-request-access-dailog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatIconButton, MatIcon],
  templateUrl: './request-access-dailog.component.html',
  styleUrl: './request-access-dailog.component.scss'
})
export class RequestAccessDailogComponent {

}
