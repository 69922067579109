<div class="background " role="main">

  <div class="container d-flex justify-content-center align-items-center text-center mt-4">
    <!--<div class="mat-elevation-z1" matRipple matRippleColor="#7030A044" style="background-color: rgb(255, 255, 255); border-radius: 10px; height: 300px; width: 50%;">-->
    <mat-card class="news-form-container">
      <p class="h3 mt-3"> Add News </p>
      
      <form class="m-4" [formGroup]="newsForm" (ngSubmit)="onSubmit()">
        <!-- Title Field -->
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input matInput id="newsTitle" formControlName="newsTitle" maxlength="300" minlength="15" />
          <mat-error *ngIf="newsTitle?.hasError('required')">
            Title is required.
          </mat-error>
          <mat-error *ngIf="newsTitle?.hasError('minlength')">
            Title must be at least 15 characters long.
          </mat-error>
        </mat-form-field>

        <!-- Description Field -->
        <mat-form-field appearance="fill" style="height: 120px !important;">
          <mat-label>Description</mat-label>
          <textarea matInput id="newsDescription" minlength="50" maxlength="1500" formControlName="newsDescription" style="height: 120px !important;"></textarea>
          <!--<mat-error *ngIf="newsDescription?.invalid && newsDescription?.touched">
        <div *ngIf="newsDescription?.hasError('required')">Description is required.</div>
        <div *ngIf="newsDescription?.hasError('minlength')">Description must be at least 10 characters long.</div>
      </mat-error>-->
          <mat-error *ngIf="newsDescription?.hasError('required')">
            Description is required.
          </mat-error>
          <mat-error *ngIf="newsDescription?.hasError('minlength')">
            Description must be at least 50 characters long.
          </mat-error>
        </mat-form-field>
        <!-- Last Date Field -->
        <mat-form-field appearance="fill">
          <mat-label>Last Date</mat-label>
          <input matInput [min]="today" [matDatepicker]="picker" formControlName="lastDate" id="lastDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="lastDate?.hasError('required')">
            Last Date is required.
          </mat-error>
          <mat-error *ngIf="lastDate?.hasError('minDate')">
            Date must be today or later.
          </mat-error>
        </mat-form-field>

        <!-- Submit Button -->
        <div class="form-actions">
          <!--<button mat-raised-button color="primary" type="submit" [disabled]="newsForm.invalid">-->
          <button disabled="{{newsForm.invalid==true}}" mat-flat-button color="primary" type="submit">
            <!--{{ newsModel ? 'Update' : 'Add' }} News-->
            Submit
          </button>

        </div>
      </form>
    </mat-card>
    <!--</div>-->


  </div>

</div>


