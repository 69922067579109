import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { BehaviorSubject, delay, filter, Observable, Observer, of } from 'rxjs';

import { LoggerService } from '../logger.service';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAuthentication } from '../../models/UserAuthentication';
import { APIAuthentication } from '../../models/API-Authentication';
import { BaseHttpclientService } from '../base-httpclient.service';
import { APIConstants } from '../../../environments/APIConstants';


@Injectable({
  providedIn: 'root',
  deps:[HttpClientModule]
})
export class LoginService {

    //login=new BehaviorSubject(true);
  // constructor(private authService: MsalService,private msalBroadcastService: MsalBroadcastService) { 
  //   this.setLogin(false);
  //  // this.handleOfficeLogin();
  // }



   IsAuthenticated = new BehaviorSubject(false);
   isForcedLogout = new BehaviorSubject(true);
 
   GRAPH_PHOTO_ENDPOINT = "https://graph.microsoft.com/v1.0/me/photo/$value";
   userData = new BehaviorSubject<UserAuthentication>({
 
     EmailId: "not found",
     IsAuthenticated: false,
     Name: "Unknown",
 
   })
   apiData = new BehaviorSubject<APIAuthentication>({
     apiKey: '',
     permissions: [],
     role: ''
   })
 
   constructor(private basehttp: BaseHttpclientService, private logger: LoggerService, private sanitizer: DomSanitizer, private http: HttpClient, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
 
   }
 
 
   
   setLoginData(data: UserAuthentication) {
 
     
     this.userData.next({
 
       EmailId: data.EmailId,
       IsAuthenticated: data.IsAuthenticated,
       Name: data.Name,
     });
     this.IsAuthenticated.next(true);
     this.isForcedLogout.next(false);
 
   }
  //  setLogoutState(forcedLogout: boolean) {
 
  //    localStorage.setItem("mstoken", "");
  //    localStorage.setItem("apiToken", "");
  //    this.isForcedLogout.next(forcedLogout);
  //  }
    logout() {
 
 
  //    this.setLogoutState(true);
  //    this.authService.logoutRedirect();
 
    }
 
 
  //  GetAPITokenFromInterceptor(){
 
  //    var localToken = localStorage.getItem("apiToken");
   
  //    if (localToken != null && localToken != "" && localToken != "undefined"){
     
  //       var permissions=localStorage.getItem("permissions");
  //        return of({apiKey:localToken,permissions:permissions});
 
  //    }
  //    else
  //    {
 
  //      var loggedInUser = localStorage.getItem("email");
  //      var token = localStorage.getItem("mstoken");
  //     return this.basehttp.get(APIConstants.EndPoints.CREATE_SESSION, "accessToken=" + token + "&email=" + loggedInUser);
  //    }
 
     
 
  //  }
 
  // async  updateAccountInformation(activeAccount: AccountInfo) {
 
  //    let user: UserAuthentication = {
  //      IsAuthenticated: true,
  //      Name: activeAccount.name || "Unknown",
  //      EmailId: activeAccount.username,
 
  //    }
  //    localStorage.setItem("email", activeAccount.username);
  //    this.setLoginData(user);
     
  //  }
 
  //   getAPIToken(callback: any) {
 
  // 
 
  //    var localToken = localStorage.getItem("apiToken");
  //    if (localToken != null && localToken != "")
  //      callback(localToken);
  //      else {
  //       
  //      this.logger.log("LoginService->>GetAPIToken", "Getting token", "");
  //      var loggedInUser = localStorage.getItem("email");
  //      var token = localStorage.getItem("mstoken");
  //      this.basehttp.get(APIConstants.EndPoints.CREATE_SESSION, "accessToken=" + token + "&email=" + loggedInUser).subscribe((data: any) => {
  //        var token = data.apiKey;
  //        localStorage.setItem("apiToken", token);
  //        var role = data.role;
  //        var permissions = data.permissions;
  //        var updatedInformation: APIAuthentication = {
  //          apiKey: token,
 
  //          permissions: permissions,
  //          role: role
  //        }
  //        this.apiData.next(updatedInformation);
  //        callback(token);
  //      });
 
 
  //    }
 
 
 
  //  }
  //  async getToken() {
 
 
 
  //    const token = await localStorage.getItem("mstoken");
  //    if (token == null || token == "") {
 
  //      return await this.authService.acquireTokenSilent(this.msalGuardConfig.authRequest as SilentRequest)
  //    }
  //    else
  //      return token;
 
  //  }
 
  tokenRetry=0;
  // async generateToken(){
 
  //  await delay(3000);
 
  //  var activeAccount = this.authService.instance.getActiveAccount();
  //    if(activeAccount==null){
  //    
  //        await delay(3000);
  //    
  //        this.generateToken();
  //        this.tokenRetry++;
  //        if(this.tokenRetry>3){
 
  //        
 
  //        }
 
       
 
 
  //    }
  //    else{
 
  //        await delay(3000);
  //        this.getToken().then((data:any)=>{
 
 
              
 
 
  //        })
 
 
 
  //    }
   
 
 
  //  }
  //  testAdminAccess(){
  //    this.basehttp.get(APIConstants.testAdminAccess,"").subscribe((test:any)=>{

 
  //    });
  //  }
   userPic: any;
   getProfilePicture(callback: any) {
 
     const headers = new HttpHeaders();
 
     headers.append('content-type', 'image/jpeg');
 
     this.http.get(this.GRAPH_PHOTO_ENDPOINT, { responseType: 'blob', headers: headers })
       .subscribe(profile => {
 
        
         let objectURL = URL.createObjectURL(profile);
         this.userPic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
 
 
         callback(this.userPic);
       });
 
 
 
 
   }
 
   //
  //  login() {
 


  //    var activeAccount = this.authService.instance.getActiveAccount();
  //    if (activeAccount != null) {
 
  //      this.updateAccountInformation(activeAccount);
       
      
 
  //    }
  //    else {
  //      const accounts = this.authService.instance.getAllAccounts();
  //      if (accounts.length > 0) {
  //        this.authService.instance.setActiveAccount(accounts[0]);
        
         
       
  //        this.logger.log("LoginService->>login", "account found " + accounts.length + " setting first as active", accounts[0]);
  //        const activeAccount: AccountInfo = accounts[0];
  //        if (activeAccount != null) {
  //          //save the current page information and then return to the same page after login in again
  //          this.updateAccountInformation(activeAccount);
  //        }
  //        else {
  //          this.logger.log("LoginService->>login", "edge case accounts found but failed to get the 0th one", "")
  //        }
  //      }
  //      else {
  //        // Need to check
  //       //  this.authService.instance.addEventCallback((event: { eventType: EventType; payload: { account: any; }; }) => {
  //       //    // set active account after redirect
  //       //    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
  //       //      const account = event.payload.account;
  //       //      this.authService.instance.setActiveAccount(account);
            
 
  //       //    }
  //       //    this.logger.log("LoginService->>login", "Callback function", "")
  //       //  });
  //        if (this.IsAuthenticated.value == false) {
 
  //          if (this.msalGuardConfig.authRequest ) {
  //            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  //          } else {
  //            this.authService.loginRedirect();
  //          }
 
  //        }
 
  //      }
  //    }
 
    
    
 
 
  //  }
 
 
 
 
  //  checkLogin() {
     
  //    const accounts = this.authService.instance.getAllAccounts();
  //    if (accounts.length > 0) {
 
  //    }
  //    else {
  //     this.login();
  //    }
 
  //  }


}
