import { Component, Inject, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { NgFor } from '@angular/common';
@Component({
  selector: 'app-news-dailog',
  standalone: true,
  imports: [NgFor,MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatIconButton, MatIcon],
  templateUrl: './news-dailog.component.html',
  styleUrl: './news-dailog.component.css'
})
export class NewsDailogComponent {
   
  isNext: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { value: any }) {
   
  }

  currentIndex = 0;

  prev(): void {
    this.isNext = false;
    this.currentIndex = (this.currentIndex - 1 + this.data.value.length) % this.data.value.length;
  }

  next(): void {
    this.isNext = true;
    this.currentIndex = (this.currentIndex + 1) % this.data.value.length;
  }

}
