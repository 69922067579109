<mat-toolbar class="toolbar-background mat-elevation-z2 " color="primary">
   
    <mat-toolbar-row>
        <span><img src="Acc_Logo_Black_Purple_RGB.png" height="30px"></span>
        &nbsp; 
        <div class="verticalLine">
        </div> 
       
        <span><img src="logo.png" height="30px"></span>
        <h1 class="fill-space ms-2 font-weight-bold"><b>UCP HUB</b></h1>


        <div class="">
            <div >
                <a    color="accent"  mat-button   routerLink="/home"  routerLinkActive="mat-secondary"><mat-icon>
                  home</mat-icon></a>
                <!--<a    color="accent"  mat-button   routerLink="/home" [queryParams]="{id:sessionId}" routerLinkActive="mat-secondary"><mat-icon>
                  home</mat-icon></a>-->
                
              
                <a mat-button color="accent" *ngIf="isAdmin"  routerLink="/add-news" routerLinkActive="mat-secondary">News <mat-icon>add</mat-icon></a>
                <a mat-button color="accent" (click)="openRequestAccessDialog()" routerLinkActive="mat-secondary">Request Access <mat-icon>add</mat-icon></a>
                <a mat-button color="accent"   routerLink="/help-support" routerLinkActive="mat-secondary">Help & Support <mat-icon>help</mat-icon></a>
                <!-- <a mat-button [routerLink]="'/standalone'"> Standalone Tools </a> -->
                <!-- <a mat-button color="accent"  rout routerLinkActive="mat-accent"> Restricted </a> -->
              
              
             
                <mat-menu style="background-color: antiquewhite !important;" col #appMenu="matMenu">
                  <button color="accent" class="text-success b" [matTooltip]="userData.EmailId" *ngIf="isLoggedIn" mat-menu-item>{{userData.Name}}</button>
                    <!-- <button color="primary"  *ngIf="isLoggedIn" (click)="getLastSession()" mat-menu-item>History</button> -->
                    <button color="accent" *ngIf="isLoggedIn"  (click)="logout()" mat-menu-item>Logout</button>
                     <!-- <button color="accent"  mat-menu-item>{{version}}</button> -->
                  </mat-menu>
                  
                  <img  mat-mini-fab class="avatar mat-elevation-z1" color="warm"  [matMenuTriggerFor]="appMenu" *ngIf="userPic!=null" [src]="userPic" height="35" width="35" alt="profilePic">
                  <button *ngIf="userPic==null"  class="avatar mat-elevation-z2"  color="warm" mat-fab-button [matMenuTriggerFor]="appMenu">
                    <mat-icon >person</mat-icon>
                  </button>
                <!-- <a mat-button [routerLink]="'/create-activity'"> Create Activity </a> -->
            </div>
        </div>
        
    </mat-toolbar-row>
 
  
   
   
 

  </mat-toolbar>
