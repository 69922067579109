import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, timeInterval } from 'rxjs';

import { AlertMessageService } from '../../services/alertmessage.service';



@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {


  
  sessionId=new BehaviorSubject("");
  connectionStatus=new BehaviorSubject(false);
  currentRetries=0;
  isConnected=false;
  currentId="";
  intervalId=""
  
  setSessionId(sessionId:string){

     this.sessionId.next(sessionId);
     this.currentId=sessionId;

  }

  onConnectionCreated(isConnected:boolean){

     this.isConnected=isConnected;
     this.connectionStatus.next(this.isConnected);

 let id=  setInterval(()=>{



          debugger;
          this.currentRetries++;
     



     },10000);

  }

  constructor(private alert:AlertMessageService) { }
}
