

<mat-dialog-content class="app-details-dialog-content">
  <!-- Dialog Header -->
  <div class="dialog-header">
    <h2 class="dialog-title">App Details</h2>
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Dialog Content -->
  <div class="dialog-body">
    <div class="app-detail-section">
     
      <p class="detail-content">  {{data.value ? data.value.appTitle: ''}}</p>
    </div>

    <div class="app-detail-section">
   
      <p class="detail-content">{{data.value ? data.value.appDescriptionLong: ''}}</p>
    </div>

    <div class="app-detail-section">
      <span class="detail-title">Point of Contact : </span>
      <p class="detail-content ms-2">{{data.value ? data.value.poc: ''}}</p>
    </div>

    <div class="app-detail-section">
      <h3 class="detail-title">App Link: </h3>
      <p class="detail-content">
       <small class="ms-3"> <a [href]="data.value.link" target="_blank">Launch</a></small>
        <!-- <a [href]="dlLink" target="_blank">Contact DL</a> -->
      </p>
    </div>
  </div>
</mat-dialog-content>